var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.displayedHeader,"items":_vm.historyAction,"item-key":"documentId","hide-default-footer":"","loading":_vm.isLoading,"loading-text":"Sedang memuat...","items-per-page":100},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.actionDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.actionDate)))]),_c('p',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.timeFormat(item.actionDate)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{class:_vm.getStyle(index)},[_vm._v(_vm._s(_vm.formatAction(item.action)))])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('pre',[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(item.note ? item.note : '-'))])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }